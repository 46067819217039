.editable-note-actions {
  margin-top: 16px;
}

.editable-note-actions button + button {
  margin-left: 16px;
}

.mde-header {
  background: none;
}
